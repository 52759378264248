import React from "react"
import Column from "../../components/column"
import Columns from "../../components/columns"
import Paragraph from "../../components/paragraph"
import Heading from "../../components/heading"
import Stack from "../../components/stack"
import VideoPlayer from "../../components/video-player"

const VideosVote = ({ title, description, poster, src }) => {
  return (
    <Columns collapse={[true, false]} alignY="center" space={12}>
      <Column width={["100%", "1/2", "2/5"]}>
        <VideoPlayer src={src} poster={poster} />
      </Column>
      <Column>
        <Stack space={6}>
          <Heading level={4}>{title}</Heading>
          <Paragraph>{description}</Paragraph>
        </Stack>
      </Column>
    </Columns>
  )
}

export default VideosVote
