import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Stack from "../../components/stack"
import NoHyphen from "../../components/no-hyphen"
import VideosStaticItem from "./videos-static-item"

const VideosStatic = (props) => {
  const data = useStaticQuery(graphql`
    query VideosStatic {
      posterFamilie: file(
        relativePath: { eq: "videos/poster-12-fakten-familie.png" }
      ) {
        ...faktenPosterImage
      }
      posterPfarrer: file(
        relativePath: { eq: "videos/poster-12-fakten-pfarrer.png" }
      ) {
        ...faktenPosterImage
      }
      posterVerliebt: file(
        relativePath: { eq: "videos/poster-12-fakten-verliebt.png" }
      ) {
        ...faktenPosterImage
      }
      posterWanderer: file(
        relativePath: { eq: "videos/poster-12-fakten-wanderer.png" }
      ) {
        ...faktenPosterImage
      }
      posterDichter: file(
        relativePath: { eq: "videos/poster-12-fakten-dichter.png" }
      ) {
        ...faktenPosterImage
      }
      posterFruehaufsteher: file(
        relativePath: { eq: "videos/poster-12-fakten-fruehaufsteher.png" }
      ) {
        ...faktenPosterImage
      }
      posterGeisteskrank: file(
        relativePath: { eq: "videos/poster-12-fakten-geisteskrank.png" }
      ) {
        ...faktenPosterImage
      }
      posterKrankenhaus: file(
        relativePath: { eq: "videos/poster-12-fakten-krankenhaus.png" }
      ) {
        ...faktenPosterImage
      }
      posterGedichteAusDemTurm: file(
        relativePath: {
          eq: "videos/poster-12-fakten-gedichte-aus-dem-turm.png"
        }
      ) {
        ...faktenPosterImage
      }
      posterImTurm: file(
        relativePath: { eq: "videos/poster-12-fakten-im-turm.png" }
      ) {
        ...faktenPosterImage
      }
      posterScardanelli: file(
        relativePath: { eq: "videos/poster-12-fakten-scardanelli.png" }
      ) {
        ...faktenPosterImage
      }
      posterJahreszahlen: file(
        relativePath: { eq: "videos/poster-12-fakten-jahreszahlen.png" }
      ) {
        ...faktenPosterImage
      }
    }
  `)

  const videos = [
    {
      title: "Die Familie",
      id: "video-familie",
      description: (
        <>
          Friedrich Hölderlin wird im Jahr 1770 geboren. <br />
          Vor 250 Jahren. <br />
          Seine Mutter ist da 22 Jahre alt. <br />
          Sein Vater stirbt schon 2 Jahre nach Hölderlins Geburt. <br />
          Hölderlin hat 2 jüngere Geschwister: <br />
          Rike und Karl.
        </>
      ),
      poster: data.posterFamilie,
      src: "12-fakten/familie",
    },
    {
      title: "Der Pfarrer",
      id: "video-pfarrer",
      description: (
        <>
          Hölderlins Mutter möchte, dass ihr Sohn Pfarrer wird. <br />
          Dafür kommt Hölderlin mit 18 Jahren nach Tübingen. <br />
          Dort studiert er Theologie. <br />
          Es gefällt ihm aber gar nicht. <br />
          Darüber schreibt er auch ein Gedicht. <br />
          Es beginnt mit den Worten <br />
          »Ich duld‘ es nimmer!«
        </>
      ),
      poster: data.posterPfarrer,
      src: "12-fakten/pfarrer",
    },
    {
      title: "Verliebt",
      id: "video-verliebt",
      description: (
        <>
          Mit 26 Jahren verliebt sich Hölderlin in eine Frau <br />
          mit dem Namen Susette Gontard. <br />
          Sie ist die Frau seines <NoHyphen>Arbeit∙gebers</NoHyphen>. <br />
          Hölderlin unterrichtet die Kinder von Herrn und Frau Gontard. <br />
          Auch Susette verliebt sich in Hölderlin. <br />
          Sie haben eine heimliche <NoHyphen>Liebes∙beziehung</NoHyphen>.
        </>
      ),
      poster: data.posterVerliebt,
      src: "12-fakten/verliebt",
    },
    {
      title: "Der Wanderer",
      id: "video-wanderer",
      description: (
        <>
          Hölderlin liebt die Natur. <br />
          Er wandert gerne. <br />
          Manchmal sogar mehr als 50 Kilometer <br />
          an einem Tag. <br />
          Mehrere Tage und Wochen <NoHyphen>hinter∙einander</NoHyphen>. <br />
          Später <NoHyphen>ent∙fernt</NoHyphen> er sich aber kaum noch <br />
          von seinem Zuhause im Turm.
        </>
      ),
      poster: data.posterWanderer,
      src: "12-fakten/wanderer",
    },
    {
      title: "Der Dichter",
      id: "video-dichter",
      description: (
        <>
          Wenn Hölderlin schreibt, <br />
          macht er mit Worten Musik. <br />
          Er spricht sie laut vor sich hin. <br />
          Er zählt ihre Silben. <br />
          Und er klopft dabei <br />
          mit der Hand auf den <NoHyphen>Schreib∙tisch</NoHyphen>. <br />
          Damit er den passenden Rhythmus <br />
          für seine Gedichte findet.
        </>
      ),
      poster: data.posterDichter,
      src: "12-fakten/dichter",
    },
    {
      title: "Der Früh∙aufsteher",
      id: "video-fruehaufsteher",
      description: (
        <>
          Hölderlin wacht am Morgen schon vor der Sonne auf.
          <br />
          Manchmal schon um 4 oder 5 Uhr morgens.
          <br />
          Er steigt aus dem Bett,
          <br />
          zieht sich die Schuhe an
          <br />
          und läuft los.
          <br />
          <NoHyphen>Stunden∙lang</NoHyphen> geht er vor dem Turm auf und ab.{" "}
          <br />
          Noch vor dem Frühstück.
          <br />
          Er ist ein echter <NoHyphen>Früh∙aufsteher</NoHyphen>.
        </>
      ),
      poster: data.posterFruehaufsteher,
      src: "12-fakten/fruehaufsteher",
    },
    {
      title: "Der Geistes∙kranke",
      id: "video-geisteskrank",
      description: (
        <>
          Hölderlin wird krank.
          <br />
          <NoHyphen>Geistes∙krank</NoHyphen>.<br />
          Er ist oft traurig.
          <br />
          Und wütend.
          <br />
          Er sagt Dinge, die keiner versteht.
          <br />
          Deshalb kommt er nach Tübingen ins <NoHyphen>Kranken∙haus</NoHyphen>.
          <br />
          Dort wird er behandelt.
          <br />
          231 Tage lang.
          <br />
          Aber die Ärzte können ihm nicht helfen. <br />
          Er wird als »unheilbar <NoHyphen>geistes∙krank</NoHyphen>« entlassen.
        </>
      ),
      poster: data.posterGeisteskrank,
      src: "12-fakten/geisteskrank",
    },
    {
      title: "Im Kranken∙haus",
      id: "video-krankenhaus",
      description: (
        <>
          Im <NoHyphen>Kranken∙haus</NoHyphen> wird Hölderlin
          <br />
          mit Kräutern und Salben behandelt. <br />
          Diese Kräuter sind giftig. <br />
          Die Patienten leiden darunter. <br />
          Manchmal darf Hölderlin aber auch <br />
          im Garten vom <NoHyphen>Kranken∙haus</NoHyphen> spazieren gehen.{" "}
          <br />
          Das gefällt ihm.
        </>
      ),
      poster: data.posterKrankenhaus,
      src: "12-fakten/krankenhaus",
    },
    {
      title: "Gedichte aus dem Turm",
      id: "video-gedichte-aus-dem-turm",
      description: (
        <>
          Auch während seiner Zeit im Turm <br />
          schreibt Hölderlin Gedichte. <br />
          Aber diese Gedichte sind ganz anders. <br />
          Sie klingen anders als seine früheren Gedichte. <br />
          Auch ihr Inhalt ist anders. <br />
          Hölderlin schreibt jetzt über die <NoHyphen>Jahres∙zeiten</NoHyphen>.
        </>
      ),
      poster: data.posterGedichteAusDemTurm,
      src: "12-fakten/gedichte-aus-dem-turm",
    },
    {
      title: "Im Turm",
      id: "video-im-turm",
      description: (
        <>
          Nach der Zeit im <NoHyphen>Kranken∙haus</NoHyphen>
          <br />
          kommt Hölderlin zur Familie Zimmer. <br />
          Sie haben ein Haus mit Turm. <br />
          Es liegt direkt am Neckar in Tübingen. <br />
          Hölderlin bleibt dort bis zu seinem Tod. <br />
          36 Jahre wohnt er in dem Turm bei der Familie Zimmer. <br />
          Heute heißt der Turm »<NoHyphen>Hölderlinturm</NoHyphen>«.
        </>
      ),
      poster: data.posterImTurm,
      src: "12-fakten/im-turm",
    },
    {
      title: "Scardanelli",
      id: "video-scardanelli",
      description: (
        <>
          Im Turm gibt sich Hölderlin einen neuen Namen. <br />
          Er will nicht mehr Hölderlin heißen. <br />
          Er will »Scardanelli« genannt werden. <br />
          Mit diesem Namen unterschreibt er auch seine Gedichte.
        </>
      ),
      poster: data.posterScardanelli,
      src: "12-fakten/scardanelli",
    },
    {
      title: "Falsche Jahres∙zahlen",
      id: "video-jahreszahlen",
      description: (
        <>
          »Scardanelli« schreibt unter seine Gedichte <br />
          oft ein völlig falsches Datum. <br />
          Die <NoHyphen>Jahres∙zahlen</NoHyphen> liegen manchmal 200 Jahre in
          der Vergangenheit. <br />
          Oder 100 Jahre in der Zukunft.
        </>
      ),
      poster: data.posterJahreszahlen,
      src: "12-fakten/jahreszahlen",
    },
  ]

  return (
    <Stack>
      {videos.map((video) => (
        <VideosStaticItem key={video.id} {...video} />
      ))}
    </Stack>
  )
}

export default VideosStatic
