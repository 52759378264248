import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../../layouts/default"

import Breadcrumb from "../../../components/breadcrumb"
import Paragraph from "../../../components/paragraph"
import NoHyphen from "../../../components/no-hyphen"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"
import Trivia from "../../../components/trivia"
import WordBreak from "../../../components/word-break"
import VideosStatic from "../../../content/12-fakten/videos-static"
import HoelderlinLiebtNavigation from "../../../content/hoelderlin-liebt/navigation"
import SectionSpacing from "../../../components/section-spacing"

const Fakten = () => {
  const data = useStaticQuery(graphql`
    query AttributionFakten {
      ogImage: file(relativePath: { eq: "og_images/12-fakten.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
    }
  `)
  return (
    <Layout backdrop="aussen">
      <Seo
        title="12 Fakten über Friedrich Hölderlin in Gebärdensprache"
        description=" In 12 kurzen Videos stellt unsere Gebärdensprach-Dolmetscherin Rita Mohlau 12 spannende Fakten zu Friedrich Hölderlin vor."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <SectionSpacing>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Digital",
                link: "/digital",
              },
              {
                title: "Sonderausstellungen",
                link: "/sonderausstellungen",
              },
              {
                title: "Hölderlin liebt",
                link: "/sonderausstellungen/hoelderlin-liebt",
              },
              {
                title: "12 Fakten",
                link: "/sonderausstellungen/hoelderlin-liebt/12-fakten",
              },
            ]}
          />
          <PageTitle>
            12 Fakten <br /> über Friedrich <NoHyphen>Hölderlin</NoHyphen> in
            Gebärdensprache
          </PageTitle>

          <Paragraph>
            In 12 kurzen Videos stellt unsere Gebärdensprach-Dolmetscherin Rita
            Mohlau 12 spannende Fakten zu Friedrich Hölderlin vor.
          </Paragraph>
        </Stack>

        <Stack>
          <VideosStatic />
          <Trivia title="Übrigens">
            <p>
              Für Rita Mohlau ist die <WordBreak>Gebärden∙sprache</WordBreak>{" "}
              ihre <WordBreak>Mutter∙sprache</WordBreak>. Ihre Mutter und ihr
              Vater sind beide <WordBreak>gehör∙los</WordBreak>. Sie selbst kann
              hören. Sie ist eine CODA.
            </p>
          </Trivia>
        </Stack>

        <HoelderlinLiebtNavigation />
      </SectionSpacing>
    </Layout>
  )
}

export default Fakten
